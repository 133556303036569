import ApiService from "@/services/api.service";
import store from "@/store/index";
import { NotifiyClient } from "@/models/notification";

export const project = {
  namespaced: true,
  state: {
    my: null,
    projects: null,
    selected: null,
    onboarding: null,
  },
  actions: {
    getOnboardingOfProject({ commit }, id) {
      if (id) {
        return ApiService.post("onboarding/get", { id: id }).then(
          (response) => {
            commit("getOnboardingSuccess", response.data);
          },
          (error) => {
            console.log(error);
            store.dispatch(
              "notificationsStore/add",
              new NotifiyClient("warning", "Fehler", false, ".")
            );
          }
        );
      }
    },
    async getMyProjects({ commit }) {
      store.dispatch("loading/toggle", true);
      try {
        const response = await ApiService.get("project/mine");
        console.log("Project Response", response.data);
        commit("getMyProjectsSuccess", response.data);
        store.dispatch("loading/success");
      } catch (error) {
        console.log(error);
        store.dispatch(
          "notificationsStore/add",
          new NotifiyClient(
            "warning",
            "Fehler",
            false,
            "Deine Projekte konnten nicht geladen werden."
          )
        );
        store.dispatch("loading/error");
      }
    },
    getAllProjects({ commit }) {
      return ApiService.get("project/getAll").then(
        (response) => {
          commit("getAllProjectsSuccess", response.data);
        },
        (error) => {
          console.log(error);
          store.dispatch(
            "notificationsStore/add",
            new NotifiyClient(
              "warning",
              "Fehler",
              false,
              "Projekte konnten nicht geladen werden."
            )
          );
        }
      );
    },
  },
  mutations: {
    getMyProjectsSuccess(state, data) {
      state.my = data;
      state.selected = data[0];
    },
    getAllProjectsSuccess(state, data) {
      state.projects = data;
    },
    getOnboardingSuccess(state, data) {
      state.onboarding = data;
    },
  },
};
