<template>
  <div v-if="projectDataC">
    <v-navigation-drawer
      color="background"
      mobile
      class="mt-2"
      :class="rail ? '' : 'px-2'"
      location="right"
      :permanent="$vuetify.display.smAndDown ? false : true"
      width="380"
      bottom
      :rail="$vuetify.display.mdAndUp ? rail : false"
      v-model="isExpanded"
      @click="rail = false"
    >
      <template v-slot:prepend>
        <div class="d-flex align-center">
          <v-btn
            :icon="rail ? 'mdi-arrow-left' : 'mdi-arrow-right'"
            flat
            @click.stop="
              $vuetify.display.mdAndUp
                ? (rail = !rail)
                : (isExpanded = !isExpanded)
            "
            class="d-flex justify-center bg-background"
          >
          </v-btn>
          <span class="font-weight-medium ml-6">Details zum Projekt</span>
        </div>
      </template>
      <v-list class="mt-1" v-model:opened="open">
        <v-list-group
          expand-icon=""
          collapse-icon=""
          class="rounded"
          value="projektInfos"
          :class="rail ? 'bg-background' : 'bg-backgroundShade'"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              flat
              prepend-icon="mdi-clipboard-text-outline"
              title="Projekt Informationen"
              v-bind="props"
            ></v-list-item>
          </template>
          <div class="pa-2">
            <ProjectInfoItem
              :date="projectDataC.estimatedProjectEnd"
              :description="projectDataC.description"
              :projectId="projectDataC.id"
              :admin="isAdmin"
              @update:description="handleDescriptionUpdate"
              @update:date="handleDateUpdate"
            />
          </div>
        </v-list-group>
        <v-list-group
          expand-icon=""
          collapse-icon=""
          value="onboarding"
          class="mt-4 rounded"
          :class="rail ? 'bg-background' : 'bg-backgroundShade'"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              flat
              prepend-icon="mdi-laptop-account"
              title="Onboarding"
              v-bind="props"
            ></v-list-item>
          </template>
          <div class="pa-2">
            <OnboardingItem
              class="bg-background"
              v-if="projectDataC.onboardingId"
              :onboardingId="projectDataC.onboardingId"
              :admin="isAdmin"
            />
          </div>
        </v-list-group>
        <v-list-group
          expand-icon=""
          collapse-icon=""
          value="ansprechpartner"
          class="mt-4 rounded"
          :class="rail ? 'bg-background' : 'bg-backgroundShade'"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              flat
              prepend-icon="mdi-phone-outline"
              title="Ansprechpartner"
              v-bind="props"
            ></v-list-item>
          </template>
          <div class="pa-2 d-flex flex-column ga-2">
            <ContactItem
              v-if="projectDataC.projectContact"
              :userId="projectDataC.projectContact"
              over-headline="Projektabwicklung"
            ></ContactItem>
            <ContactItem
              v-if="projectDataC.salesContact"
              :userId="projectDataC.salesContact"
              over-headline="Vertrieb"
            ></ContactItem>
          </div>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <v-row>
        <v-col class="d-flex align-center" cols="12">
          <v-progress-linear
            :clickable="isAdmin ? true : false"
            :class="{ 'disabled-progress': !isAdmin }"
            @click="saveProject"
            class="px-2 rounded w-100 rounded-lg"
            v-model="projectDataC.progress"
            :color="
              $vuetify.theme.current.dark ? 'accent' : 'rgba(84, 68, 253, 0.6)'
            "
            height="40"
          >
            <template v-slot:default="{ value }">
              <strong style="font-size: 0.8rem"
                >Gesamtprojekt zu {{ Math.ceil(value) }}% abgeschlossen</strong
              >
            </template>
          </v-progress-linear>
          <v-btn
            v-if="$vuetify.display.smAndDown"
            :icon="isExpanded ? 'mdi-arrow-right' : 'mdi-arrow-left'"
            flat
            @click.stop="
              isExpanded = !isExpanded;
              rail = false;
            "
            class="d-flex justify-center bg-background"
          >
          </v-btn>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-start">
            <v-timeline
              align-top
              truncate-line="end"
              side="end"
              class="justify-start mb-5 w-100"
            >
              <UpdateNew
                v-if="isAdmin"
                @updateProject="updateProject"
                :project-id="projectDataC.id"
              />
              <UpdateItem
                v-for="item of sortedUpdates"
                :key="item.id"
                @updateProject="updateProject"
                :update-item="item"
                :project-id="projectDataC.id"
              />
            </v-timeline>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContactItem from "@/views/Projekte/ContactItem.vue";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem.vue";
import ProjectInfoItem from "@/views/Projekte/ProjectInfoItem.vue";
import UpdateItem from "@/views/Projekte/UpdateItem";
import UpdateNew from "@/views/Projekte/UpdateNew";
import ApiService from "@/services/api.service";
export default {
  name: "ProjectItem",
  components: {
    UpdateNew,
    UpdateItem,
    OnboardingItem,
    ContactItem,
    ProjectInfoItem,
  },
  props: {
    projectData: Object,
    id: String,
  },
  data() {
    return {
      projectDataC: this.projectData,
      tabs: {
        selected: null,
        items: ["Info", "Prozesse", "Serviceanfragen"],
      },
      ansprechpartner: {
        sales: null,
        project: null,
      },
      isExpanded: true,
      rail: false,
      open: ["projektInfos", "onboarding", "ansprechpartner"],
    };
  },
  watch: {
    rail(newVal) {
      if (newVal) {
        this.open = [];
      } else {
        this.open = ["projektInfos", "onboarding", "ansprechpartner"];
      }
    },
    "$store.state.project.selected"(newVal) {
      this.projectDataC = newVal;
      console.log("ProjectData", this.projectDataC);
    },
  },
  computed: {
    // projectDataC: {
    //   // return this.projectData;
    //   get() {
    //     return this.projectData;
    //   },
    //   set(value) {
    //     this.$emit("update:projectData", value);
    //   },
    // },
    isAdmin() {
      return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
    },
    sortedUpdates() {
      return [...this.projectDataC.updateItems].sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
    },
  },
  async created() {
    if (this.$vuetify.display.smAndDown) {
      this.isExpanded = false;
    }
    await this.getProject();
  },
  methods: {
    handleDateUpdate(newDate) {
      this.projectDataC.estimatedProjectEnd = newDate;
      console.log("Date final", newDate);
    },
    handleDescriptionUpdate(newDescription) {
      this.projectDataC.description = newDescription;
    },
    async getProject() {
      try {
        if (this.id) {
          const response = await ApiService.get("project/get/" + this.id);
          this.projectDataC = response.data;
          await this.$store.dispatch(
            "project/getOnboardingOfProject",
            this.projectDataC.onboardingId
          );
        } else {
          await this.$store.dispatch(
            "project/getOnboardingOfProject",
            this.projectDataC.onboardingId
          );
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    },
    updateProject(project) {
      this.projectDataC.updateItems = project.updateItems;
    },
    async saveProject() {
      if (this.isAdmin) {
        try {
          const response = await ApiService.post(
            "project/update",
            this.projectDataC
          );
          this.updateProject(response.data);
        } catch (error) {
          console.error("Error updating project:", error);
        }
      }
    },
  },
};
</script>

<style scoped></style>
