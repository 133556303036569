<template>
  <v-app id="app">
    <Sidebar v-if="!anonymous" />
    <Topbar v-if="!anonymous" />
    <v-main>
      <router-view @scrollTop="scrollTop" />

      <AppNotifications />
      <!-- <v-alert
        max-width="400px"
        v-for="(notification, index) of this.$store.state.notificationsStore
          .notifications"
        :dark="!notification.type"
        :key="index"
        closable
        style="z-index: 999"
        class="position-absolute bottom-0 right-0 mr-4 mb-4"
        :class="{
          animate__fadeOutRight: notification.animation,
          accent: !notification.type,
        }"
        :type="notification.type"
      >
        <v-row align="center">
          <v-col class="grow">
            <span v-html="notification.text"></span>
            <div v-if="notification.subtext">
              <small v-html="notification.subtext"></small>
            </div>
          </v-col>
          <v-col v-if="notification.action" class="shrink">
            <v-btn
              color="primary"
              size="small"
              @click="$router.push(notification.action)"
              ><span v-html="notification.buttonText"></span
            ></v-btn>
          </v-col>
        </v-row>
      </v-alert> -->

      <v-dialog
        v-if="currentUser"
        v-model="currentUser.changePasswordOnNextLogin"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5">
            Bitte ändere dein Passwort.
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                Mit diesen Daten kannst du dich nun jederzeit im Elephant
                Kundenportal einloggen.
              </p>
              <v-form v-model="valid">
                <v-text-field
                  label="EMail"
                  placeholder="Email"
                  disabled
                  v-model="currentUser.email"
                ></v-text-field>

                <v-text-field
                  label="Neues Passwort"
                  placeholder="Neues Passwort"
                  type="password"
                  :rules="passwordRules"
                  v-model="newPassword"
                ></v-text-field>
                <v-checkbox
                  v-model="acceptAGB"
                  label="Ich akzepiere dass Elephant meine Daten speichert, sowie die AGB & Datenschutz"
                ></v-checkbox>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              :disabled="!valid || !acceptAGB"
              @click="changePassword"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SuccessDialog v-if="!anonymous" />
    </v-main>
    <!-- <v-footer app>
      <Mobilebar class="hidden-lg-and-up" />
    </v-footer> -->
  </v-app>
</template>

<script>
import Topbar from "@/views/Navigation/Topbar";
import Sidebar from "@/views/Navigation/Sidebar";
// import Mobilebar from "@/views/Navigation/Mobilebar";
import { changePassword } from "@/services/user.service";
import SuccessDialog from "@/views/Prozesse/OnBoarding/SuccessDialog";
import AppNotifications from "@/AppNotifications.vue";
//TODO Add Frontend to see Processes and Projects; And remove old DME STuff
export default {
  name: "AppVue",
  components: {
    AppNotifications,
    // Mobilebar,
    Topbar,
    Sidebar,
    SuccessDialog,
  },
  data() {
    return {
      acceptAGB: false,
      heightNav: null,
      deferredPrompt: null,
      newPassword: "",
      valid: false,
      passwordRules: [
        (v) => !!v || this.$t("login.passwordRequired"),
        (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            "Min. 8 characters with at least one capital letter, a number and a special character."
          );
        },
      ],
    };
  },

  created() {
    this.installPWA();
    //this.loadContacts();
    //if(this.currentUser) this.registerFirebase();
  },
  computed: {
    anonymous() {
      return this.$route.meta.anonymous;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    dark() {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {
    this.$store.state.app.initialHistory = window.history.length;
  },
  methods: {
    changePassword() {
      console.log("Change PW", this.newPassword);
      changePassword(this.newPassword);

      //this.currentUser.changePasswordOnNextLogin=false;
    },
    scrollTop() {
      this.$nextTick(() => {
        console.log("Scrolling top", this.$refs.mainContainer);
        this.$refs.mainContent.scrollTop = 0;
      });
    },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    installPWA() {
      console.log("PWA Install?");
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        console.log("Prompt " + e);
      });
      window.addEventListener("appinstalled", () => {
        console.log("app installed");
        this.deferredPrompt = null;
      });
    },

    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>

<style>
#mainContainer {
  min-height: 100vh;
}
@media screen and (max-width: 600px) {
  #mainContainer {
    margin-top: 60px;
    min-height: 100vh;
  }
}
</style>
