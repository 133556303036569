<template>
  <v-card flat class="" color="backgroundShade">
    <v-date-input
      color="accent"
      label="vrs. Projektabschluss"
      v-model="newDate"
      bg-color="background"
      :readonly="!admin"
      hide-details
      variant="solo"
      prepend-icon=""
      class="mb-3"
      flat
      @blur="updateDate()"
    ></v-date-input>
    <v-textarea
      @change="updateDescription()"
      v-model="newDescription"
      hide-details
      flat
      bg-color="background"
      color="accent"
      rows="3"
      max-rows="5"
      auto-grow
      label="Projektbeschreibung"
      :readonly="!admin"
      class="border-background"
      variant="solo"
    ></v-textarea
  ></v-card>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "ProjectInfoItem",
  data() {
    return { newDate: new Date(this.date), newDescription: this.description };
  },
  props: {
    admin: Boolean,
    projectId: Number,
    date: String,
    description: String,
  },
  emits: ["update:description", "update:date"],
  // computed: {
  //   dateC: {
  //      get() {
  //       return this.date;
  //     },
  //     // set(value) {
  //     //   this.updateDate(value);
  //     // },
  //   },
  //   descriptionC: {
  //     get() {
  //       return this.description;
  //     },
  //     set(value) {
  //       this.updateDescription(value);
  //     },
  //   },
  // },
  watch: {
    projectId(newId) {
      console.log("New ID", newId);
      this.newDate = new Date(this.date);
      this.newDescription = this.description;
    },
  },
  created() {
    console.log("Date type using typeof:", typeof this.date);
    console.log("Date type using instanceof Date:", this.date instanceof Date);
  },
  methods: {
    async updateDate() {
      const formattedDate = this.formatDate(this.newDate);
      try {
        const response = await ApiService.post(
          `project/${this.projectId}/update/estimatedProjectEnd`,
          { date: formattedDate }
        );
        console.log("Response", response);

        this.$emit("update:date", formattedDate);
      } catch (e) {
        console.log("Error", e);
      }
    },
    async updateDescription() {
      try {
        await ApiService.post(`project/${this.projectId}/update/description`, {
          text: this.newDescription,
        });
        this.$emit("update:description", this.newDescription);
      } catch (e) {
        console.log("Error", e);
      }
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style scoped>
/* remove shadow from v-date-picker */
.v-textarea {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}
</style>
