<template>
  <div
    class="d-flex h-100 flex-column justify-center align-center"
    v-if="!$store.state.project.my & !$store.state.loading.loadingState"
  >
    <DotLottieVue
      class="w-75 w-md-50 h-auto"
      autoplay
      loop
      src="https://lottie.host/1fe93674-6981-416a-aacd-e588be546c66/eLIrQ9MAKm.json"
    />
    <p class="text-center font-weight-medium">Keine Projekte</p>
  </div>
  <div class="h-100" v-else>
    <ProjectItem
      v-if="$store.state.project.selected"
      :projectData="$store.state.project.selected"
    />
  </div>
</template>

<script>
import ProjectItem from "@/views/Projekte/ProjectItem.vue";
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";
export default {
  name: "ProjectWrapper",
  components: {
    ProjectItem,
    DotLottieVue,
  },
  data() {
    return {};
  },
  // computed: {
  //   projects() {
  //     return this.$store.state.project.my;
  //   },
  // },
  created() {
    this.$store.dispatch("project/getMyProjects");
    console.log("Selected: ", this.$store.state.project.selected);
  },
};
</script>

<style scoped>
.v-card {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.v-card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
