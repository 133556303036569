<template>
  <v-fab
    app
    appear
    size="75"
    elevation="7"
    class="me-8 pe-4 mb-4"
    rounded="circle"
    location="bottom end"
    color="accent"
    icon="mdi-plus"
    @click="show = true"
  >
  </v-fab>
  <v-dialog
    :max-width="$vuetify.display.mdAndUp ? '65%' : '100%'"
    scrollable
    v-model="show"
  >
    <v-card>
      <v-card-title class=""> Neues {{ type }} erstellen </v-card-title>
      <v-card-text>
        <v-row class="px-4 py-2" wrap>
          <v-col cols="12" md="6">
            <v-text-field
              variant="outlined"
              v-model="onboardingRequest.customerName"
              label="Vorname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              variant="outlined"
              v-model="onboardingRequest.customerLastname"
              label="Nachname"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="email"
              variant="outlined"
              v-model="onboardingRequest.customerEmail"
              label="Kunden-Email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              variant="outlined"
              v-model="onboardingRequest.projectName"
              label="Projektname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              variant="outlined"
              :items="users"
              item-value="id"
              item-title="firstName"
              v-model="onboardingRequest.salesContact"
              label="Sales Ansprechpartner"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              variant="outlined"
              :items="users"
              item-value="id"
              item-title="firstName"
              v-model="onboardingRequest.projectContact"
              label="Projekt Ansprechpartner"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="onboardingRequest.manuelCreationOfFolders"
              label="Kundenordner ist bereits erstellt"
              color="accent"
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="onboardingRequest.manuelCreationOfFolders"
          >
            <v-text-field
              hint="Diesen findet man im Addressfeld, wenn man den Ordner im Drive öffnet."
              variant="outlined"
              v-model="onboardingRequest.schnittstellenFolderId"
              label="ID der Schnittstelle"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="onboardingRequest.manuelCreationOfFolders"
          >
            <v-text-field
              hint="Diesen findet man im Addressfeld, wenn man den Ordner im Drive öffnet."
              variant="outlined"
              v-model="onboardingRequest.folderId"
              label="ID des Kundenornders"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="show = false">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          :loading="$store.state.loading.loadingState"
          @click="createOnboarding"
          >{{ type }} jetzt anlegen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//TODO switch if projectfiles should be created automatically
//If not provide textfield for Schnittstelle usw.
import ApiService from "@/services/api.service";
import { NotifiyClient } from "@/models/notification";
export default {
  name: "CreateOnboarding",
  props: {
    type: String,
  },
  data() {
    return {
      show: false,
      users: [],
      createCustomerFolderManual: false,
      onboardingRequest: {
        projectName: "",
        customerName: "",
        customerLastname: "",
        customerEmail: "",
        salesContact: "",
        projectContact: "",
        manuelCreationOfFolders: false,
        schnittstellenFolderId: "",
        folderId: "",
      },
    };
  },
  async created() {
    await this.getAllUsers();
  },
  methods: {
    connectGoogle() {
      ApiService.get("onboarding/connectGoogle");
    },
    async createOnboarding() {
      let self = this;
      this.$store.dispatch("loading/toggle", true);
      try {
        const response = await ApiService.post(
          "onboarding/create",
          this.onboardingRequest
        );
        if (response.status === 200) {
          self.$store.dispatch("loading/success");
          self.$store.dispatch(
            "notificationsStore/add",
            new NotifiyClient(
              "success",
              "Onboarding gestartet.",
              false,
              "Das Onboarding " +
                response.data.id +
                " wurde gestartet & der Kunde wurde benachrichtigt."
            )
          );
          self.show = false;
        } else {
          self.$store.dispatch("loading/error");
          self.$store.dispatch(
            "notificationsStore/add",
            new NotifiyClient(
              "error",
              "Fehlgeschlagen.",
              false,
              "Das Onboarding konnte nicht erstellt werden."
            )
          );
        }
      } catch (error) {
        self.$store.dispatch("loading/error");
        self.$store.dispatch(
          "notificationsStore/add",
          new NotifiyClient(
            "error",
            "Fehlgeschlagen.",
            false,
            "Das Onboarding konnte nicht erstellt werden."
          )
        );
        console.error("Error creating onboarding:", error);
      }
    },
    async getAllUsers() {
      try {
        const response = await ApiService.get("user/getAllAdmins");
        this.users = response.data;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
  },
};
</script>

<style scoped></style>
