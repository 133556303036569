import store from "@/store";
import ApiService from "@/services/api.service";

export function nextStep(payload) {
  if (!payload) {
    return ApiService.post("onboarding/step/check", {
      id: store.state.processes.onboarding.instanceId,
    }).then((response) => {
      console.log("Next STep", response.data);
      return response.data;
    });
  } else {
    return ApiService.post("onboarding/step/check/payload", payload).then(
      (response) => {
        console.log("Next STep with payload", response.data);
        return response.data;
      }
    );
  }
}
export function getBack() {
  return ApiService.post("onboarding/step/back", {
    id: store.state.processes.onboarding.instanceId,
  }).then((response) => {
    if (response.data) {
      store.state.processes.onboarding.currentStep = response.data;
    }
  });
}
export function saveData(onboarding) {
  return ApiService.post("onboarding/data/save", onboarding).then(
    (response) => {
      if (response.data) {
        store.state.processes.onboarding.data = response.data;
      }
    }
  );
}

export function getOnboardingData() {
  return ApiService.post("onboarding/data/get", {
    id: store.state.processes.onboarding.instanceId,
  }).then((response) => {
    store.state.processes.onboarding.data = response.data;
  });
}
