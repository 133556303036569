<template>
  <v-container class="bg-primary">
    <v-tabs
      v-model="selected"
      color="transparent"
      active-class="activeTab"
      class="recap-tabs"
    >
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab
        v-for="item in steps"
        :key="item"
        :disabled="checkIfStepIsCompleted(item)"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items class="transparent" v-model="selected">
      <v-tab-item key="2 von 7">
        <Two recap />
      </v-tab-item>
      <v-tab-item key="3 von 7">
        <Three recap />
      </v-tab-item>
      <v-tab-item key="4 von 7">
        <Four recap />
      </v-tab-item>
      <v-tab-item key="5 von 7">
        <Five recap />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Two from "@/views/Prozesse/OnBoarding/Steps/Two";
import Three from "@/views/Prozesse/OnBoarding/Steps/Three";
import Four from "@/views/Prozesse/OnBoarding/Steps/Four";
import Five from "@/views/Prozesse/OnBoarding/Steps/Five";
export default {
  name: "OnBoardingRecap",
  components: { Five, Four, Three, Two },
  data() {
    return {
      steps: ["2 von 7", "3 von 7", "4 von 7", "5 von 7"],
      selected: "2 von 7",
    };
  },
  methods: {
    checkIfStepIsCompleted(item) {
      console.log(item.substring(0, 1));
      console.log(this.$store.state.processes.onboarding.currentStep);
      return (
        item.substring(0, 1) * 1 >
        this.$store.state.processes.onboarding.currentStep
      );
    },
  },
};
</script>

<style scoped></style>
