<template>
  <v-container>
    <VideoWrapper
      title="Infos zu Ihrer Marke und Ihrem Unternehmen"
      :video-url="require('@/assets/onboardingvideos/Onboarding_Teil2.mp4')"
    ></VideoWrapper>

    <v-form v-if="form" class="my-5" ref="form" v-model="valid">
      <v-text-field
        flat
        variant="solo-filled"
        rounded
        label="Was ist Ihr offizieller Firmenname?"
        hint="z.B. 'XY GmbH' oder im Fall eines Einzelunternehmers 'Max Mustermann'"
        v-model="form.companyName"
      />

      <FormSubheader title="Eigenschaften" />

      <InputGroup
        title="Welche 3 Adjektive beschreiben die wichtigsten Charaktereigenschaften Ihrer Marke?"
      >
        <v-text-field
          flat
          variant="solo-filled"
          rounded
          class="mt-1"
          hide-details
          v-model="form.adjective1"
          placeholder="Adjektiv 1..."
        ></v-text-field>
        <v-text-field
          flat
          variant="solo-filled"
          rounded
          class="mt-1"
          hide-details
          v-model="form.adjective2"
          placeholder="Adjektiv 2..."
        ></v-text-field>
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.adjective3"
          placeholder="Adjektiv 3..."
        ></v-text-field>
      </InputGroup>
      <InputGroup
        title="Welche Werte, Stile oder Charakteristika treffen auf Ihr Wunschdesign am ehesten zu?"
      >
        <v-chip-group
          v-model="form.styles"
          multiple
          column
          active-class="primary"
        >
          <v-chip
            v-for="style of styles"
            :value="style"
            :input-value="style"
            :key="style"
            color="accent"
            >{{ style }}</v-chip
          >
        </v-chip-group>
      </InputGroup>

      <InputGroup title="Nicht - Wünsche">
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          v-model="form.donts"
          placeholder="Beschreiben Sie explizit, was Sie sich für Ihre Seite NICHT wünschen."
        ></v-textarea>
      </InputGroup>

      <FormSubheader title="Farben & Schriften" />

      <v-switch
        v-model="form.newBranding"
        color="accent"
        label="Elephant soll das Branding der neuen Seite (Farben, Schriftarten) übernehmen."
      ></v-switch>

      <InputGroup
        v-if="form.newBranding"
        title="Haben Sie schon festgelegte Farben oder Schriftarten für Ihre Marke?"
      >
        <v-textarea
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Farben"
          v-model="form.colors"
          hint="Bitte trennen Sie Ihre Farben mit Komma."
          placeholder="z.B. Brandfarbe1: #3839, Brandfarbe2:#24354, Accentfarbe: #8978, textfarbe:#89790"
        ></v-textarea>
        <v-textarea
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Schriften"
          v-model="form.schrift"
          placeholder="Schriften mit , getrennt, z.B. Arial, Times"
        ></v-textarea>
      </InputGroup>

      <InputGroup
        title="Bitte geben Sie Links zu 2-3 Referenz-Websites an, an denen sich Elephant beim Design Ihrer neuen Website orientieren kann."
      >
        <v-textarea
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          v-model="form.referenceSites"
          hint="Beschreiben Sie für jeden Referenz-Website-Link kurz, was Ihnen daran gut gefällt."
          placeholder="z.B. https://www.google.de - CTA Button sehr gut gelungen"
        ></v-textarea>
      </InputGroup>

      <InputGroup
        title="Bitte geben Ihre Kontakt Email-Adresse an, über die Ihre Kunden Sie erreichen können."
      >
        <v-text-field
          flat
          variant="solo-filled"
          rounded
          type="email"
          v-model="form.contactEmail"
          label=""
          placeholder="z.B. info@elephant-agency.de"
        ></v-text-field>
      </InputGroup>
      <OnboardingNavigation v-if="!recap" :form="form" @next="$emit('next')" />
      <!-- <v-row v-if="!recap" class="mt-2">
        <v-col cols="6" lg="4" order="1"
          ><v-btn
            block
            color="backgroundShade"
            prepend-icon="mdi-arrow-left"
            @click="stepBack"
            :size="$vuetify.display.mdAndDown ? 'large' : 'default'"
            >Zurück</v-btn
          ></v-col
        ><v-col
          cols="12"
          lg="4"
          :order="$vuetify.display.mdAndDown ? 4 : 2"
          :class="{ 'my-0 py-0': $vuetify.display.mdAndDown }"
          ><v-btn
            color="backgroundShade"
            block
            size="default"
            @click="saveStepData"
            text
            >Zwischenspeichern</v-btn
          ></v-col
        ><v-col cols="6" lg="4" order="3"
          ><v-btn
            :size="$vuetify.display.mdAndDown ? 'large' : 'default'"
            block
            append-icon="mdi-arrow-right"
            color="primary"
            :loading="$store.state.loading.loadingState"
            @click="checkStep"
            >Weiter</v-btn
          ></v-col
        ></v-row
      > -->
    </v-form>
  </v-container>
</template>

<script>
import {
  getOnboardingData,
  // nextStep,

  // saveData,
  // getBack,
} from "@/services/onboarding.service";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";
import OnboardingNavigation from "@/views/Prozesse/OnBoarding/Steps/OnboardingNavigation";
export default {
  name: "StepTwo",
  components: { VideoWrapper, FormSubheader, InputGroup, OnboardingNavigation },
  data() {
    return {
      valid: false,
      styles: [
        "klar und übersichtlich",
        "minimalistisch und leicht",
        "geradlinig und bodenständig",
        "seriös und vertrauensvoll",
        "verspielt und fröhlich",
        "extravagant und ausgefallen",
        "dezent, elegant und hochwertig",
        "edel und teuer",
        "stark und dynamisch",
        "persönlich und emotional",
        "business like",
        "retro",
        "zeitlos",
        "modern und trendig",
      ],
    };
  },
  props: {
    recap: Boolean,
  },
  computed: {
    form() {
      return this.$store.state.processes.onboarding.data;
    },
  },
  async created() {
    await getOnboardingData();
  },
  // methods: {
  //   stepBack() {
  //     getBack();
  //   },
  //   saveStepData() {
  //     saveData(this.$store.state.processes.onboarding.data);
  //   },
  //   back() {
  //     //TODO Implement back frontend
  //   },
  //   checkStep() {
  //     //TODO Go to Server and check step 1 if good increase activeStep
  //     this.$store.dispatch("loading/toggle", true);
  //     nextStep(this.form).then(() => {
  //       window.scrollTo(0, 0);
  //       this.$emit("next");
  //     });
  //   },
  // },
};
</script>

<style scoped></style>
