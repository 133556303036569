<template>
  <v-container class="mt-10" v-if="loading">
    <v-row
      ><v-col cols="12" md="6" v-for="n in 4" :key="n"
        ><v-skeleton-loader
          :elevation="2"
          type="paragraph"
          class="py-5"
        ></v-skeleton-loader></v-col
    ></v-row>
  </v-container>

  <v-container v-else>
    <div
      class="d-flex h-100 flex-column justify-center align-center"
      v-if="!$store.state.project.projects & !$store.state.loading.loadingState"
    >
      <DotLottieVue
        class="w-75 w-md-50 h-auto"
        autoplay
        loop
        src="https://lottie.host/1fe93674-6981-416a-aacd-e588be546c66/eLIrQ9MAKm.json"
      />
      <p class="text-center font-weight-medium">
        Aktuell gibt es keine Projekte...
      </p>
    </div>

    <v-row v-else class="d-flex justify-start h-100 mt-5">
      <v-col
        v-for="project of $store.state.project.projects"
        :key="project.id"
        cols="12"
        md="6"
      >
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              id="card"
              class="pa-2"
              color="backgroundShade"
              v-bind="props"
              :elevation="isHovering ? 7 : 4"
            >
              <ProjectItemCard :project="project"></ProjectItemCard>
              <v-divider class="mx-5 my-3"></v-divider>
              <OnboardingItem
                class="bg-backgroundShade"
                adminView
                :onboardingId="project.onboardingId"
              ></OnboardingItem>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
  <CreateOnboarding type="Projekt" v-show="!loading" />
</template>

<script>
import ProjectItemCard from "@/views/Admin/ProjectItemCard.vue";
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";
import CreateOnboarding from "@/views/Admin/CreateOnboarding.vue";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem";

export default {
  name: "AdminProjectList",
  components: {
    ProjectItemCard,
    DotLottieVue,
    CreateOnboarding,
    OnboardingItem,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    projects() {
      return this.$store.state.project.my;
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("project/getAllProjects");
    this.loading = false;
    console.log(this.projects);
  },
};
</script>

<style scoped>
#card {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

#card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
