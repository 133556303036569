<template>
  <v-container class="mt-10" v-if="loading">
    <v-row
      ><v-col cols="12" md="6" v-for="n in 4" :key="n"
        ><v-skeleton-loader
          :elevation="2"
          type="paragraph"
          class=""
        ></v-skeleton-loader></v-col
    ></v-row>
  </v-container>
  <v-container v-else fill-height>
    <div
      class="d-flex h-100 flex-column justify-center align-center"
      v-if="!list.length & !loading"
    >
      <DotLottieVue
        class="w-75 w-md-50 h-auto"
        autoplay
        loop
        src="https://lottie.host/1fe93674-6981-416a-aacd-e588be546c66/eLIrQ9MAKm.json"
      />
      <p class="text-center font-weight-medium">Keine Onboardings</p>
    </div>

    <v-row v-else class="d-flex justify-start h-100 mt-5">
      <v-col cols="12" md="6" v-for="onboarding of list" :key="onboarding.id">
        <v-card id="card" class="bg-backgroundShade"
          ><OnboardingItem
            class="bg-backgroundShade"
            admin
            :onboardingId="onboarding.id"
          ></OnboardingItem> </v-card
      ></v-col>
    </v-row>
  </v-container>
  <CreateOnboarding type="Onboarding" v-show="!loading" />
</template>

<script>
import ApiService from "@/services/api.service";
import CreateOnboarding from "@/views/Admin/CreateOnboarding.vue";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem";
import { DotLottieVue } from "@lottiefiles/dotlottie-vue";

export default {
  name: "AdminOnboardingList",
  components: { OnboardingItem, CreateOnboarding, DotLottieVue },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  async created() {
    this.loading = true;
    await this.getOnboardings();
    this.loading = false;
  },
  methods: {
    async getOnboardings() {
      try {
        const response = await ApiService.get("onboarding/getAll");
        this.list = response.data;
        console.log("Onboardings", this.list);
      } catch (error) {
        console.error("Error fetching onboardings:", error);
      }
    },
  },
};
</script>

<style scoped>
#card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

#card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
