<template>
  <v-container>
    <VideoWrapper
      title="Zugänge & Social Media"
      :video-url="require('@/assets/onboardingvideos/Onboarding_Teil3.mp4')"
    ></VideoWrapper>

    <v-form v-if="form" class="my-5 mx-2" ref="form" v-model="valid">
      <FormSubheader title="Zugangsdaten" />
      <InputGroup title="Aktuelle Seite">
        <v-text-field
          flat
          variant="solo-filled"
          rounded
          v-model="form.oldSiteUrl"
          label="Url zur aktuellen Seite"
        />

        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.oldSite[0]"
          label="URL zum Login Ihrer alten Seite."
        />
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.oldSite[1]"
          label="Benutzername."
          hint="Bitte legen Sie, falls möglich, einen neuen User für Elephant an."
        />
        <v-text-field
          class="mt-1"
          flat
          variant="solo-filled"
          rounded
          type="password"
          v-model="form.oldSite[2]"
          label="Passwort des Benutzers."
          hint="Diese Daten werden verschlüsselt übermittelt & gespeichert."
        />
      </InputGroup>

      <InputGroup title="Hoster">
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.hosting[0]"
          label="URL zum Login des Hosters."
        />
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.hosting[1]"
          label="Benutzername bei Hoster."
        />
        <v-text-field
          class="mt-1"
          flat
          variant="solo-filled"
          rounded
          v-model="form.hosting[2]"
          type="password"
          label="Passwort bei Hoster."
          hint="Diese Daten werden verschlüsselt übermittelt & gespeichert."
        />
      </InputGroup>

      <FormSubheader title="Socials" />
      <InputGroup
        title="Bitte geben Sie hier Ihre Social Media Kanäle ein Name/URL, sodass wir diese in der Website einbauen können."
      >
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.instagram"
          label="Instagram Account"
        ></v-text-field>
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.facebook"
          label="Facebook Account"
        ></v-text-field>
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.twitter"
          label="Twitter Account"
        ></v-text-field>
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.youtube"
          label="Youtube Account"
        ></v-text-field>
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.linkedIn"
          label="LinkedIn Account"
        ></v-text-field>
        <v-text-field
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          v-model="form.extraSocial"
          label="Weitere Accounts"
        ></v-text-field>
      </InputGroup>
      <OnboardingNavigation v-if="!recap" :form="form" @next="$emit('next')" />
    </v-form>
  </v-container>
</template>

<script>
import { getOnboardingData } from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";
import OnboardingNavigation from "@/views/Prozesse/OnBoarding/Steps/OnboardingNavigation";
export default {
  //TODO Get OnBoarding Data on create so old Data is there
  name: "StepThree",
  components: { FormSubheader, InputGroup, VideoWrapper, OnboardingNavigation },
  data() {
    return {
      valid: false,
    };
  },
  props: {
    recap: Boolean,
  },
  computed: {
    form() {
      let form = this.$store.state.processes.onboarding.data;
      if (form) {
        if (!form.hosting) {
          form.hosting = ["", "", ""];
        }
        if (!form.hosting[0]) {
          form.hosting[0] = "";
        }
        if (!form.hosting[1]) {
          form.hosting[1] = "";
        }
        if (!form.hosting[2]) {
          form.hosting[2] = "";
        }

        if (!form.oldSite) {
          form.oldSite = ["", "", ""];
        }
        if (!form.oldSite[0]) {
          form.oldSite[0] = "";
        }
        if (!form.oldSite[1]) {
          form.oldSite[1] = "";
        }
        if (!form.oldSite[2]) {
          form.oldSite[2] = "";
        }
      }
      return form;
    },
  },
  created() {
    getOnboardingData();
  },
};
</script>

<style scoped></style>
