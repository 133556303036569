<template>
  <v-card
    v-if="onboarding && project"
    flat
    class="pa-3 d-flex"
    :to="'/processes/onboarding/' + onboardingId"
    ><div>
      <v-progress-circular
        :model-value="(onboarding.activeStep / 7) * 100"
        :size="adminView ? 60 : 80"
        :width="adminView ? 6 : 8"
        color="primary"
      >
        <template v-slot:default>
          <template v-if="onboarding.activeStep >= 7">
            <v-icon>mdi-check</v-icon>
          </template>
          <template v-else> {{ onboarding.activeStep }}/7 </template>
        </template>
      </v-progress-circular>
    </div>
    <div>
      <v-card-title
        class="text-h5 text-capitalize my-0 py-0"
        :style="{
          fontSize: adminView ? '1.2rem !important' : '1.5rem !important',
        }"
        >{{ adminView ? "Onboarding" : project.name }}</v-card-title
      >
      <v-card-subtitle class="mt-2" style="font-size: 0.7rem"
        >ID {{ onboarding.id }}</v-card-subtitle
      >
    </div>
  </v-card>
</template>

<script>
import ApiService from "@/services/api.service";
import { NotifiyClient } from "@/models/notification";
export default {
  name: "OnboardingItem",
  props: {
    onboardingId: String,
    adminView: { type: Boolean, default: false },
  },
  data() {
    return {
      project: null,
      onboarding: null,
    };
  },
  watch: {
    onboardingId(newId) {
      this.getOnboardingofInstance(newId);
    },
  },
  async created() {
    console.log("Onboarding ID", this.onboardingId);
    // await this.getProjectOfInstance();
    await this.getOnboardingofInstance(this.onboardingId);
  },
  methods: {
    deleteOnboarding() {
      let self = this;
      ApiService.post("onboarding/delete", { id: this.onboarding.id }).then(
        (response) => {
          if (response.data) {
            self.$store.dispatch(
              "notificationsStore/add",
              new NotifiyClient("success", "Erfolgreich gelöscht", false)
            );
          }
        }
      );
    },
    async getOnboardingofInstance(id) {
      try {
        const response = await ApiService.post("onboarding/get", {
          id: id,
        });
        this.onboarding = response.data;
        await this.getProjectOfInstance();
      } catch (error) {
        console.log("Error", error);
      }
    },
    async getProjectOfInstance() {
      try {
        const response = await ApiService.post("onboarding/project/get", {
          id: this.onboardingId,
        });
        this.project = response.data;
      } catch (error) {
        console.log("Error", error);
      }
    },
  },
};
</script>

<style scoped></style>
