<template>
  <v-container>
    <v-progress-circular
      v-if="!login"
      size="100px"
      indeterminate
    ></v-progress-circular>
    <v-dialog v-model="login" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Willkommen zurück!</span>
        </v-card-title>
        <v-card-text>
          <p class="caption mb-4">
            Wir haben uns selbstverständlich gemerkt, dass Sie bereits ein
            Projekt bei uns hatten. <br />
            Bitte loggen Sie sich daher mit Ihren Accountdaten ein.
          </p>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  rounded
                  prepend-inner-icon="mdi-email-outline"
                  variant="outlined"
                  autofocus
                  label="E-Mail"
                  type="email"
                  required
                  class="w-100 mb-0 pb-0"
                  v-model="emailC"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  rounded
                  prepend-inner-icon="mdi-lock-outline"
                  label="Passwort"
                  variant="outlined"
                  :append-inner-icon="
                    passwordVisible ? 'mdi-eye-off' : 'mdi-eye'
                  "
                  @click:append-inner="passwordVisible = !passwordVisible"
                  :type="passwordVisible ? 'text' : 'password'"
                  required
                  class="mb-0 pb-0"
                  v-model="password"
                  @keypress.enter="handleLogin"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            variant="flat"
            color="accent"
            @click="handleLogin"
            class="rounded-elephant"
          >
            Einloggen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";
import { NotifiyClient } from "@/models/notification";

export default {
  name: "StepStart",
  data() {
    return {
      passwordVisible: false,
      login: false,
      password: "",
    };
  },
  props: {
    user: String,
    email: String,
  },
  computed: {
    emailC: {
      get() {
        return this.email;
      },
      set(value) {
        this.$emit("update:email", value);
      },
    },
  },
  created() {
    console.log("user", this.user);
    
      if (!this.$store.state.auth.status.loggedIn) {
        this.handleAutoLogin();
      } else {
        ApiService.post("onboarding/step/check", {
          id: this.$store.state.processes.onboarding.instanceId,
        }).then((response) => {
          console.log("Next STep", response.data);
          this.$emit("next");
        });
      }
    
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("loading/toggle", true);
      /**
       this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }
       **/
      if (this.email && this.password) {
        this.$store
          .dispatch("auth/login", {
            email: this.email,
            password: this.password,
          })
          .then(
            () => {
              this.$store.dispatch("loading/success");
              ApiService.post("onboarding/step/check", {
                id: this.$store.state.processes.onboarding.instanceId,
              }).then((response) => {
                console.log("Next STep", response.data);
                this.$emit("next");
              });

              console.log("Login successfull");
            },
            () => {
              this.$store.dispatch("loading/error");

              this.$store.dispatch(
                "notificationsStore/add",
                new NotifiyClient(
                  "error",
                  "Error",
                  false,
                  "Login fehlgeschlagen."
                )
              );
            }
          );
      }
    },
    handleAutoLogin() {
      this.$store.dispatch("loading/toggle", true);

      if (this.email && this.user) {
        console.log("Email", this.email);
        console.log("Passwort", this.user);
        this.$store
          .dispatch("auth/login", { email: this.email, password: this.user })
          .then(
            () => {
              this.$store.dispatch("loading/success");
              ApiService.post("onboarding/step/check", {
                id: this.$store.state.processes.onboarding.instanceId,
              }).then((response) => {
                console.log("Next STep", response.data);
                this.$emit("next");
              });

              console.log("Login successfull");
            },
            () => {
              this.$store.dispatch("loading/error");
              this.$store.dispatch(
                "notificationsStore/add",
                new NotifiyClient("error", "Error", false, "Please try again.")
              );
            }
          );
      }
    },
  },
};
</script>

<style scoped></style>
