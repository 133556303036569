<template>
  <v-card
    :to="{
      name: 'Projekt',
      params: { id: project.id },
    }"
    flat
    class="px-2 pt-2 bg-backgroundShade"
  >
    <v-card-title class="d-flex justify-space-between align-center">
      <span>{{ project.name }}</span>
      <v-chip color="accent" size="small">{{
        project.typ
      }}</v-chip></v-card-title
    >
    <v-card-subtitle>ID {{ project.id }}</v-card-subtitle>
    <v-card-text>
      <div class="d-flex justify-center align-center ga-5">
        <v-progress-linear
          :model-value="project.progress"
          rounded
          color="primary"
          height="12"
        ></v-progress-linear
        ><strong style="font-size: 1.2rem"
          >{{ Math.ceil(project.progress) }}%</strong
        >
      </div></v-card-text
    >
  </v-card>
</template>

<script>
export default {
  name: "ProjectItemCard",
  props: {
    project: Object,
  },
  created() {
    console.log(this.project);
  },
};
</script>

<style scoped></style>
