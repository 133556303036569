<template>
  <div>
    <v-row v-if="!recap" class="mt-5">
      <v-col cols="6" lg="4" order="1"
        ><v-btn
          block
          color="backgroundShade"
          prepend-icon="mdi-arrow-left"
          @click="stepBack"
          :loading="$store.state.loading.loadingState"
          >Zurück</v-btn
        ></v-col
      ><v-col
        cols="12"
        lg="4"
        :order="$vuetify.display.mdAndDown ? 4 : 2"
        :class="{ 'my-0 py-0': $vuetify.display.mdAndDown }"
        ><v-btn
          color="backgroundShade"
          block
          :size="$vuetify.display.mdAndDown ? 'small' : 'default'"
          @click="saveStepData"
          :loading="$store.state.loading.loadingState"
          >Zwischenspeichern</v-btn
        ></v-col
      ><v-col cols="6" lg="4" order="3"
        ><v-btn
          block
          append-icon="mdi-arrow-right"
          color="primary"
          :loading="$store.state.loading.loadingState"
          @click="checkStep"
          >Weiter</v-btn
        ></v-col
      ></v-row
    >
    <v-dialog
      class="mx-5 d-flex align-center align-content-center"
      v-model="isStepFive"
      persistent
      max-width="600px"
      height="200px"
    >
      <v-card class="rounded-elephant h-100">
        <v-card-title class="ml-2 text-wrap">Wirklich fortfahren?</v-card-title>
        <v-card-text class="">
          Wenn Sie jetzt fortfahren, wird das Projekt erstellt und Ihre Eingaben
          können nicht mehr bearbeitet werden.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="isStepFive = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            append-icon="mdi-arrow-right"
            @click="executeStep"
            >Fortfahren</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { nextStep, saveData, getBack } from "@/services/onboarding.service";

export default {
  name: "OnboardingNavigation",
  emits: ["next"],
  props: {
    form: Object,
  },
  data() {
    return {
      loading: false,
      isStepFive: false,
    };
  },
  created() {
    console.log(this.$store.state.processes.onboarding.currentStep);
  },
  methods: {
    stepBack() {
      getBack();
    },
    async saveStepData() {
      this.$store.dispatch("loading/toggle", true);
      await saveData(this.$store.state.processes.onboarding.data);
      this.$store.dispatch("loading/toggle", false);
    },
    checkStep() {
      if (this.$store.state.processes.onboarding.currentStep === 5) {
        this.isStepFive = true;
      } else {
        this.executeStep();
      }
    },
    executeStep() {
      this.$store.dispatch("loading/toggle", true);
      nextStep(this.form).then(() => {
        window.scrollTo(0, 0);
        this.$emit("next");
      });
      this.isStepFive = false;
    },
  },
};
</script>
