<template>
  <v-container class="">
    <h2 class="my-4 mx-auto">Unser Servicebereich befindet sich im Bau.</h2>
    <p class="mx-auto">
      Wir arbeiten aktiv daran, die Kundenexperience zu verbessern.
    </p>
    <p>
      Daher können Kunden mit aktiven Servicepaket künftig Servicenafragen über
      unsere App stellen & auch den Bearbeitungsstatus verfolgen.
    </p>

    <p>Nutzen Sie bitte bis dahin unsere legacy Lösung</p>
    <v-btn
      class="mt-5"
      color="accent"
      href="https://elephant-agency.de/support/"
      >Jetzt Ticket aufgeben.</v-btn
    >
  </v-container>
</template>

<script>
export default {
  name: "ServiceWrapper",
};
</script>

<style scoped></style>
