<template>
  <v-container>
    <VideoWrapper
      title="Zielgruppe & Angebot"
      :video-url="require('@/assets/onboardingvideos/Onboarding_Teil5.mp4')"
    />

    <v-form v-if="form" class="my-5 mx-2" ref="form" v-model="valid">
      <FormSubheader title="Branche"></FormSubheader>
      <InputGroup>
        <v-textarea
          label="Beschreibe deine Branche"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          v-model="form.brancheBeschreibung"
        />
      </InputGroup>
      <InputGroup title="Rolle des Unternehmens in dieser Branche">
        <v-textarea
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          placeholder="Beschreiben Sie die Rolle Ihres Unternehmens in oben beschriebener Branche."
          v-model="form.brancheRolle"
        />
      </InputGroup>
      <FormSubheader title="Auflistung deinen Angebots"></FormSubheader>
      <InputGroup>
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Welche Produkte/Dienstleistungen werden angeboten?"
          v-model="form.produkteDienstleistungen"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was unterscheidet Sie von der Konkurrenz?"
          v-model="form.unterscheidenKonkurrenz"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was sind allgemeine USPs?"
          v-model="form.usps"
        />
      </InputGroup>
      <FormSubheader title="Beschriebung der Zielgruppe"></FormSubheader>
      <InputGroup>
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was wünschen sie sich von Ihrer Lösung / Ihrem Angebot?"
          v-model="form.wunschLoesungAngebot"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Wie lässt sich Ihre Zielgruppe überzeugen, was sind treffende Argumente?"
          v-model="form.argumente"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Gibt es sonstige Infos zur Zielgruppe?"
          v-model="form.sonstigesZielgruppe"
        />
      </InputGroup>
      <FormSubheader title="Ziel & Kunden"></FormSubheader>
      <InputGroup>
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was ist das Ziel der Website?"
          v-model="form.websiteZiel"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was ist Ihr Primärer CTA (Call to Action) der Website"
          hint="Geben Sie, wenn möglich, ein Besipiel an."
          v-model="form.cta"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was waren bisher typische Kunden Ihres Unternehmens?"
          v-model="form.typischeKunden"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Wie würden Sie Ihren Traumkunden beschreiben?"
          v-model="form.traumkunde"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was sind Probleme & Wünsche Ihrer Traumkunden?"
          v-model="form.problemeWuenscheTraumkunde"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Womit beschäftigen sich Ihre Traumkunden? Wovor haben Sie Angst?"
          v-model="form.gedankenTraumkunde"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Auf welche Fragen suchen diese Antworten?"
          v-model="form.fragen"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Wie können wir diesen Leuten Informationen (Website-Texte oder Artikel) weiterhelfen?"
          v-model="form.informationenWeiterhelfen"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was wünschen sich Ihre Traumkunden im Erstkontakt? Evtl. eine neutrale Beratung?"
          v-model="form.wuenscheTraumkundeErstkontakt"
        />
        <FormSubheader title="Angebot"></FormSubheader>
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was macht Ihr Angebot gut und besonders?"
          v-model="form.vorteileAngebot"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Warum ist Ihr Angebot perfekt passend für Ihre Traumkunden?"
          v-model="form.angebotPassendTraumkunde"
        />
        <v-textarea
          class="mt-1"
          hide-details
          flat
          variant="solo-filled"
          rounded
          auto-grow
          max-rows="10"
          label="Was sind Verkaufsargumente, die bei Ihrer Zielgruppe in der Vergangenheit schon gut funktioniert haben?"
          v-model="form.verkaufsargumente"
        />
      </InputGroup>

      <OnboardingNavigation v-if="!recap" :form="form" @next="$emit('next')" />

      <v-dialog
        class="mx-5 d-flex align-center align-content-center"
        v-model="$store.state.loading.loadingState"
        persistent
        max-width="600px"
        height="200px"
      >
        <v-card class="rounded-elephant h-100">
          <v-card-title class="ml-2 text-wrap"
            >Wir erstellen nun intern das Projekt. Dies kann bis zu 20sec
            dauern.</v-card-title
          >
          <v-card-text class="d-flex justify-center align-center">
            <v-progress-circular
              color="primary"
              size="90"
              width="8"
              indeterminate
            >
            </v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
//TODO FINISH Fields
import { getOnboardingData } from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";
import OnboardingNavigation from "./OnboardingNavigation.vue";
export default {
  name: "StepFive",
  components: { FormSubheader, InputGroup, VideoWrapper, OnboardingNavigation },
  props: {
    recap: Boolean,
  },
  data() {
    return {
      valid: false,
    };
  },
  computed: {
    form() {
      return this.$store.state.processes.onboarding.data;
    },
  },
  created() {
    getOnboardingData();
  },
};
</script>

<style scoped></style>
