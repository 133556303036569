<template>
  <v-container>
    <VideoWrapper
      :video-url="require('@/assets/onboardingvideos/Onboarding_Teil7.mp4')"
      title="Abschließende Worte und Kickoff-Termin"
    ></VideoWrapper>
    <!-- Calendly Inline-Widget Beginn -->

    <v-row v-if="!recap" class="mt-5">
      <v-col cols="6"
        ><v-btn
          block
          color="backgroundShade"
          prepend-icon="mdi-arrow-left"
          @click="stepBack"
          :loading="$store.state.loading.loadingState"
          >Zurück</v-btn
        ></v-col
      ><v-col cols="6"
        ><v-btn
          block
          append-icon="mdi-check"
          color="primary"
          :loading="$store.state.loading.loadingState"
          @click="checkStep"
          >Abschließen</v-btn
        ></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { nextStep, getBack } from "@/services/onboarding.service";
import ApiService from "@/services/api.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";

export default {
  name: "StepSeven",
  components: { VideoWrapper },
  methods: {
    stepBack() {
      getBack();
    },
    checkStep() {
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep().then(() => {
        this.$store.dispatch("project/getMyProjects").then(() => {
          ApiService.post("onboarding/project/get", {
            id: this.$store.state.processes.onboarding.instanceId,
          }).then((response) => {
            let project = response.data;
            let projects = this.$store.state.project.my;
            this.$store.state.project.selected =
              projects[projects.indexOf((x) => x.id === project.id)];
          });
          this.$store.state.processes.onboarding.showFinishedDialog = true;
          this.$router.push("/my/projects");
        });
      });
    },
  },
};
</script>

<style scoped></style>
